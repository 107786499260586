
import { computed } from '@vue/reactivity';
import { Checkbox } from 'ant-design-vue';
import { useField } from 'vee-validate';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {
    Checkbox
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      default: undefined
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props: any, { emit }: { emit: any }) {
    const {
      value: fieldValue,
      errorMessage,
      handleChange,
      handleBlur,
      meta,
      errors
    } = useField(props.name, props.rules, {
      initialValue: props.checked
    });
    const inputValue = computed({
      get: () => {
        return props.checked;
      },
      set: (value) => {
        emit('update:checked', value);
      }
    });
    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      errors,
      fieldValue
    };
  }
});
