/* eslint-disable no-unused-vars */
/**
 * @description:  Language
 */
export enum LanguageEnum {
      Turkish = 0,
      English = 1
}

export enum LanguageI18nEnum {
      Turkish = 'tr',
      English = 'en'
}
