import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-left flex items-center" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "input-label text-black font-semibold"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_a_select, {
      value: _ctx.inputValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      size: _ctx.size,
      class: "the-select",
      onChange: _ctx.handleChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: option.value,
            value: option.value,
            disabled: option.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.label), 1)
            ]),
            _: 2
          }, 1032, ["value", "disabled"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "name", "placeholder", "size", "onChange"]),
    _withDirectives(_createElementVNode("p", {
      class: _normalizeClass(["help-message", { 'has-error-message': !!_ctx.errorMessage }])
    }, _toDisplayString(_ctx.errorMessage), 3), [
      [_vShow, _ctx.errorMessage]
    ])
  ]))
}