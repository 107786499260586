import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-left flex items-center" }
const _hoisted_2 = { class: "w-full relative input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Checkbox, {
        checked: _ctx.inputValue,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        name: _ctx.name,
        class: _normalizeClass(["the-checkbox", { 'has-error': !!_ctx.errorMessage }]),
        onChange: _cache[1] || (_cache[1] = (event) => _ctx.handleChange(event.target.checked))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["checked", "name", "class"])
    ])
  ]))
}