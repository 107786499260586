
import { Modal as AModal } from 'ant-design-vue';
import { SelectTypes } from 'ant-design-vue/es/select';
import { isEmpty } from 'lodash-es';
import { ref, reactive, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import * as Yup from 'yup';

import { LanguageEnum, LanguageI18nEnum } from '@/enums/languageEnum';
import { LocalStorageEnum } from '@/enums/localStorageEnum';
import { IInformation, ISession } from '@/services/types';
import { useFormStore } from '@/store/modules/form';

import TheCheckbox from '@/components/TheCheckbox.vue';
import TheForm from '@/components/TheForm.vue';
import TheInput from '@/components/TheInput.vue';
import TheSelect from '@/components/TheSelect.vue';

export default defineComponent({
  components: { AModal, TheForm, TheInput, TheSelect, TheCheckbox },
  setup() {
    const store = useFormStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    // Default form things
    const formData: IInformation = reactive({
      name: '',
      email: '',
      companyName: '',
      language: LanguageI18nEnum.Turkish,
      acikriza: false,
      aydinlatma: false
    });

    // If found session on local storage
    let session: ISession = {} as ISession;
    const sessionModalVisible = ref(false);
    const localStorageSession = localStorage.getItem(LocalStorageEnum.Session);
    if (localStorageSession && !isEmpty(localStorageSession)) {
      session = JSON.parse(localStorageSession) as ISession;
      formData.language = session.language === LanguageEnum.Turkish ? LanguageI18nEnum.Turkish : LanguageI18nEnum.English;
      sessionModalVisible.value = true;
    }
    const acceptSession = () => {
      store.session = session;
      router.push({ name: session.isFinished ? 'CheckupCompleted' : 'Checkup' });
    };
    const rejectSession = () => {
      localStorage.removeItem('session');
    };
    // If found session on local storage

    // Set locale
    locale.value = formData.language?.toString() || LanguageI18nEnum.Turkish;

    const submitting = ref(false);
    const submit = async() => {
      submitting.value = true;
      formData.language = formData.language === LanguageI18nEnum.Turkish ? LanguageEnum.Turkish : LanguageEnum.English;
      await store.createSession(formData);
      router.push({ name: 'Checkup' });
    };

    const sectorList: Array<string> = ['Adalet ve Güvenlik', 'Mobilya ve Dekorasyon Ürünleri', 'Ambalaj ve Kap', 'Araç Üstü Ekipman', 'Avcılık ve Balıkçılık', 'Bilişim Teknolojileri ve Hizmetleri', 'Biyoteknoloji', 'Cam, Çimento ve Toprak', 'Danışmanlık', 'Demiryolu İmalatı', 'Denizcilik', 'Eğitim', 'Elektrik / Elektronik Üretimi', 'Enerji', 'Endüstriyel Otomasyon', 'Bankacılık ve Finansal Hizmetler', 'Gıda', 'Giyim ve Moda', 'Havacılık', 'Havayolu Taşımacılığı'];

    const sectorOptions: SelectTypes['options'] = [];
    const languageOptions: SelectTypes['options'] = [
      {
        value: LanguageI18nEnum.Turkish,
        label: t('common.turkish')
      },
      {
        value: LanguageI18nEnum.English,
        label: t('common.english')
      }
    ];

    sectorList.forEach(sector => {
      sectorOptions.push({
        value: sector,
        label: sector,
        disabled: false
      });
    });

    const switchLocale = () => {
      locale.value = formData.language?.toString() || LanguageI18nEnum.Turkish;
    };
    return {
      // Variables
      session,
      sessionModalVisible,
      formData,
      submitting,
      sectorOptions,
      languageOptions,
      locale,
      // Methods
      submit,
      acceptSession,
      rejectSession,
      switchLocale,
      // Packages
      Yup,
      LanguageEnum
    };
  }
});
